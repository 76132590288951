import React from 'react';
import spotLight from '../assets/spotlight.js';
import '../css/Spotlight.css';

class Spotlight extends React.Component{
    render(){
        return(
            <div className='spotlight__wrapper'>
                <h2 className='spotlight__title'>In de kijker</h2>
                <div className='content__wrapper'>
                    <div className='content__image--wrapper'>
                        <img src={spotLight.ImagePath} alt={spotLight.Title}/>
                    </div>
                    <div className='content__text--wrapper'>
                        <h3>{spotLight.Title}</h3>
                        <div >{spotLight.InnerHtml}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Spotlight;
