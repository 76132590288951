import imagePath from './webP/packet.webp';


const spotlight = {
	"Title": "Summer deal: Nathalie’s zomers Rosépakket à € 45",
	"InnerHtml":
		<div>
			<ul className='packet__list'>
				<li>1 Terre Forti Sangiovese à € 6,30</li>
				<li>1 Racine à € 6,80</li>
				<li>1 Belles du Sud à € 6,95</li>
				<li>1 Dune Gris de Gris à € 8,60</li>
				<li>1 Fumées Blanches Lurton à € 9</li>
				<li>1 Dorrance Cape Town à € 10,50</li>
			</ul>
			<div className='packet__appendix'>
				<p>Van fruitig fris, licht naar voller en kruidiger</p>
				<p>Bij een zomerse barbecue doet rosé het altijd goed!</p>
				<p>Bestel ons pakket, ontdek de verschillende smaken en geniet!</p>
			</div>
		</div>,
	"ImagePath": imagePath
};

export default spotlight;
