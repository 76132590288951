import React from "react";

import Header from './Header.js';
import Contact from './Contact.js';
import Spotlight from "./Spotlight.js";
import About from './About.js';
import Vineyard from './Vineyard.js';
import Newsletter from "./Newsletter.js";
import Links from './Links.js';
import Footer from './Footer.js'
import Testimonial from "./Testimonial.js";


class WnkRoot extends React.Component {
    render(){
        return(
            <div className='main__wrapper'>
                <Header/>
                <Contact/>
                <Spotlight/>
                <About/>
                <Vineyard/>
                <Newsletter/>
                <Links/>
                <Testimonial/>
                <Footer/>
            </div>
        )
    }
}



export default WnkRoot;