import React from 'react';
import janDejonghe from '../assets/webP/janDejonghe.webp';
import '../css/Testimonial.css';

class Testimonial extends React.Component{
    render(){
        return(
            <div className='testimonial__wrapper'>
                <div className='testimonial__text--wrapper'>
                    <h1>Testimonial</h1>
                    <p className='testimonial'>
                        “Voor heerlijke en selectieve wijnen voor
                        bijzondere evenementen of voor een
                        speciaal moment moet je bij Nathalie Kint
                        zijn. De hartelijke ontvangst en vrijblijvend
                        advies maken van elke ontmoeting met
                        Nathalie een aangename ervaring.”
                    </p>
                    <div className='testimonial__source'>
                        <p className='name'>~ Jan Dejonghe</p>
                        <p className='function'>Member of the Board</p>
                        <p className='company'>Abriso Jiffy</p>
                    </div>
                </div>
                <img src={janDejonghe} className='testimonial__image' alt='Jan Dejonghe'/>
            </div>
        )
    }
}

export default Testimonial;